<template>
  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always" class="topS">
        <el-form label-width="50px">
          <el-row :gutter="10" style="margin-bottom: 0px">
            <el-col :span="3">
              <el-button @click="addBtn" v-if="showadd != -1">新增</el-button>
              <el-button @click="batchesDelete" v-if="showdelete != -1"
                >批量删除</el-button
              >
            </el-col>
            <el-col :span="3">
              <el-form-item label="品牌">
                <el-select
                  v-model="seekData.brandName"
                  @visible-change="initialize"
                  @change="onQueryChangeBrand"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in brandSelectData"
                    :key="item.id"
                    :label="item.brandName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="门店">
                <el-select
                  v-model="seekData.hotelId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in queryHotelSelectData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="房间类型" label-width="80px">
                <el-input
                  placeholder="请输入内容"
                  v-model="seekData.typeName"
                  clearable
                  class="my-input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="reset-button">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>

        <el-row></el-row>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="brandName" label="品牌"></el-table-column>
            <!-- <el-table-column prop="hotelName" label="门店"></el-table-column> -->
            <el-table-column prop="typeName" label="房间类型"></el-table-column>
            <el-table-column prop="typeName" label="房型">
              <template slot-scope="scope">
                <div>
                  {{ showRoomType(scope.row.standardRoomType) }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="id" label="房间类型ID"></el-table-column>
            <!-- <el-table-column prop="remark" label="描述"></el-table-column> -->

            <!-- 操作按钮列 -->
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <!-- 编辑按钮 -->
                <el-button
                  v-if="showupdate != -1"
                  type="primary"
                  size="mini"
                  dialogFormVisible
                  @click="handleEdit(scope.row)"
                  >修改</el-button
                >
                <!-- 删除按钮 -->
                <el-button
                  v-if="showdelete != -1"
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>
    <!-- 编辑的弹框 -->
    <el-dialog
      title="编辑房类型"
      top="5vh"
      :visible.sync="dialogFormVisible2"
      class="astrict"
    >
      <el-form
        :model="editData"
        :ref="editData"
        :rules="myrules"
        :label-width="formLabelWidth"
      >
        <el-form-item label="品牌" prop="brandId">
          <el-select
            v-model="editData.brandId"
            clearable
            filterable
            @change="onEditChangeBrand"
            placeholder="请选择"
          >
            <el-option
              v-for="item in brandSelectData"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店" prop="hotelId">
          <el-select
            v-model="editData.hotelId"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in hotelSelectData"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间类型" prop="typeName">
          <el-input
            v-model.trim="editData.typeName"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="房型" prop="standardRoomType">
          <el-select
            v-model="editData.standardRoomType"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in roomTypeList"
              :key="index"
              :label="item.lable"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="描述">
          <el-input
            v-model="editData.remark"
            type="textarea"
            :rows="2"
            maxlength="150"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <el-card class="box-card"> -->
      <div class="diaologTab">
        <div class="diaologTabTitle">房间类型下的设备</div>

        <el-table
          ref="multipleTable"
          :data="sbList"
          tooltip-effect="dark"
          :height="300"
          style="width: 100%"
        >
          <el-table-column prop="eqTypeName" label="设备类型"></el-table-column>
          <el-table-column prop="eqName" label="设备名称"></el-table-column>
          <el-table-column prop="loopNumber" label="回路编号"></el-table-column>
          <!-- <el-table-column prop="brandName" label="品牌"></el-table-column>
      <el-table-column prop="brandName" label="品牌"></el-table-column> -->
        </el-table>
      </div>
      <!-- <div slot="header" class="clearfix">
     
    </div> -->
      <!-- </el-card> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmEditD(editData)"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 新增的弹框 -->
    <el-dialog
      title="新增客房类型"
      :visible.sync="dialogFormVisible"
      class="astrict"
    >
      <el-form
        :model="addform"
        ref="addform"
        :rules="myrules"
        :label-width="formLabelWidth"
      >
        <el-form-item label="品牌" prop="brandId">
          <el-select
            v-model="addform.brandId"
            clearable
            filterable
            @change="onEditChangeBrand"
            placeholder="请选择"
          >
            <el-option
              v-for="item in brandSelectData"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店" prop="hotelId">
          <el-select
            v-model="addform.hotelId"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in hotelSelectData"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间类型" prop="typeName">
          <el-input
            v-model.trim="addform.typeName"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="房型" prop="standardRoomType">
          <el-select
            v-model="addform.standardRoomType"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in roomTypeList"
              :key="index"
              :label="item.lable"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            v-model="addform.remark"
            type="textarea"
            :rows="5"
            maxlength="150"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="abrogateAdd">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmAdd('addform')"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getBoomTypelList,
  addRoomType,
  getHotelSelect,
  delRoomType,
  getBrandSelect,
  editRoomType,
  getEqlList,
} from "@/api";
import { showRoomType } from "@/api/common";

import { regionData, CodeToText } from "element-china-area-data";
export default {
  // name:"fjlx",
  data() {
    return {
      height: null,
      multipleSelection: [],
      loading: false,
      // 建筑列表数据
      tableData: null,
      dialogFormVisible2: null,
      dialogFormVisible: null,
      dialogVisible: false,
      formLabelWidth: "100px",
      // 查询的数据
      total: null,
      seekData: {
        hotelId: null,
        brandName: null,
        typeName: null,
        pageSize: 10,
        pageNum: 1,
      },
      query: {
        pageNum: 1,
        pageSize: 300,
        roomTypeId: null,
      },
      sbList: null,

      hotelId: null,
      brandId: null,
      // 新增
      addform: {
        brandId: null, //品牌
        typeName: null, //房间类型
        remark: null, //
        hotelId: null,
        standardRoomType: null,
      },
      // 编辑弹窗下拉框的数据
      brandSelectData: null,
      hotelSelectData: null,

      // 查询条件下拉框的数据
      queryHotelSelectData: null,
      options: regionData,
      selectedOptions: [],
      // 编辑
      editData: {
        hotelId: null,
        brandId: null, //品牌
        typeName: null, //房间类型
        remark: null, //
        standardRoomType: null,
      },
      myrules: {
        typeName: [{ required: true, message: "请输入内容", trigger: "blur" }],
        brandId: [{ required: true, message: "请选择", trigger: "change" }],
        hotelId: [{ required: true, message: "请选择", trigger: "change" }],
        standardRoomType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
      userJurisdiction: null,
      defaultProps: {
        children: "children",
        label: "title",
      },
    };
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 240;
  },
  computed: {
    // 权限
    showadd: function () {
      return this.userJurisdiction.indexOf("roomType:add");
    },
    showdelete: function () {
      return this.userJurisdiction.indexOf("roomType:delete");
    },
    showupdate: function () {
      return this.userJurisdiction.indexOf("roomType:update");
    },
    roomTypeList() {
      return this.$store.state.roomTypeList;
    },
  },

  created() {
    this.userJurisdiction = this.$store.state.roleMenu;
    // 调用初始化表格数据方法
    this.initList(this.seekData);
  },
  methods: {
    onQueryChangeBrand(id) {
      getHotelSelect(id).then((res) => {
        if (res.data.data) {
          this.seekData.hotelId = null;
          this.queryHotelSelectData = res.data.data;
        } else {
          this.$message({
            message: "该品牌下没有门店",
            type: "warning",
          });
        }
      });
    },

    onEditChangeBrand(id) {
      getHotelSelect(id).then((res) => {
        if (res.data.data) {
          this.addform.hotelId = null;
          this.hotelSelectData = res.data.data;
        } else {
          this.$message({
            message: "该品牌下没有门店",
            type: "warning",
          });
        }
      });
    },

    getEqlList() {
      getEqlList(this.query).then((res) => {
        if (res.status === 200) {
          this.sbList = res.data.data.rows;
        }
      });
    },
    showRoomType(val) {
      return showRoomType(val, this.roomTypeList);
    },
    // 初始化表格数据
    initList(obj) {
      this.loading = true;
      getBoomTypelList(obj).then((res) => {
        // console.log(res)
        if (res.status === 200) {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.seekData.pageNum > 1 && res.data.data.rows == false) {
            this.seekData.pageNum = this.seekData.pageNum - 1;
            this.initList(this.seekData);
          }
        }
      });
    },

    /* 初始化下拉框 */
    // 获取品牌下拉框
    initialize() {
      getBrandSelect().then((res) => {
        if (res.status === 200) {
          this.brandSelectData = res.data.data;
          // console.log(res)
        }
      });
    },
    // },

    /* 新增弹窗 */
    // 获取品牌下拉框
    addBtn() {
      this.dialogFormVisible = true;
      this.initialize();
      //  getEqSelect().then((res) => {
      //   // console.log(res)
      //   this.eqSelectData = res.data.data;
      //  });
    },

    // 确定按钮
    confirmAdd(formName) {
      console.log(this.addform);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          addRoomType(this.addform)
            .then((res) => {
              this.loading = false;
              if (res.data.code == "000000") {
                this.$message.success(res.data.message);
                this.initList(this.seekData);
                // this.reset()
                this.dialogFormVisible = false;
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消按钮
    abrogateAdd() {
      this.dialogFormVisible = false;
    },

    // 楼层删除
    handleDelete(row) {
      // console.log(row)
      this.$confirm("此操作将永久删除该客房类型, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 点击确定执行的操作
          // console.log(row.id)
          this.delRoomType(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delRoomType(id) {
      this.loading = true;
      delRoomType(id).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code == "000000") {
            this.$message.success("删除成功");
            this.initList(this.seekData);
          } else {
            this.loading = false;
            this.$message.error(res.data.message);
          }
        } else {
          this.loading = false;
          this.$message.error("删除失败");
        }
      });
    },

    batchesDelete() {
      if (this.multipleSelection.length != 0) {
        // 把要删除的用户ID以字符串拼接
        let number = "";
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const element = this.multipleSelection[i];
          number += element.id + ",";
        }
        number = number.slice(0, number.length - 1); //去掉最后的逗号
        this.$confirm("此操作将永久删除所有选择, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.delRoomType(number);
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.warning("请先选择要删除的数据");
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },

    // 编辑
    handleEdit(index, row) {
      this.editData.brandId = index.brandId;
      this.editData.hotelId = index.hotelId;
      this.editData.typeName = index.typeName;
      this.editData.standardRoomType = index.standardRoomType;
      this.editData.remark = index.remark;
      this.editData.id = index.id;
      this.dialogFormVisible2 = true;
      this.initialize();
      this.query.roomTypeId = index.id;
      this.getEqlList();
      //  getEqSelect().then((res) => {
      //   // console.log(res)
      //   this.eqSelectData = res.data.data;
      //  });
    },
    // 编辑确认
    confirmEditD(formName) {
      console.log(this.editData);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          editRoomType(this.editData).then((res) => {
            this.loading = false;
            if (res.data.code == "000000") {
              this.$message.success(res.data.message);
              this.initList(this.seekData);
              // this.reset()
              this.dialogFormVisible2 = false;
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 查询按钮
    handleSearch() {
      // console.log(this.seekData)
      this.seekData.pageNum = 1;
      if (this.seekData) {
        getBoomTypelList(this.seekData).then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
          }
        });
      } else {
        this.initList(this.seekData);
      }
    },
    //分页
    handleCurrentChange(cpage) {
      this.seekData.pageNum = cpage;
      this.initList(this.seekData);
    },
    handleSizeChange(psize) {
      this.seekData.pageSize = psize;
      this.seekData.pageNum = 1;
      this.initList(this.seekData);
    },
    //重置
    reset() {
      this.seekData.brandName = null;
      this.seekData.typeName = null;
      this.seekData.pageNum = 1;
      this.initList(this.seekData);
    },
  },
};
</script>
<style lang="scss" scoped>
.my-box {
  .astrict {
    .el-select {
      width: 100%;
    }
    .el-cascader {
      width: 100%;
    }
  }
  .my-iemt {
    span {
      font-size: 14px;
    }
    white-space: nowrap;
  }
  .my-input {
    max-width: 200px;
  }
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }

  .grid-content {
    border-radius: 4px;
    height: 36px;
    white-space: nowrap;
    .el-input__inner {
      max-width: 190px;
    }
    span {
      font-size: 14px;
    }
  }
}
.diaologTab {
  padding: 30px;
}
.diaologTabTitle {
  font-size: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdfe6;
}
</style>
